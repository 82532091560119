/* App.css */

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.full-screen-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  background-color: #aa9574;
  scrollbar-width: none;
}

.section {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  color: white;
  margin: 0;
  padding: 0;
}

.bothalf {
  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  color: white;
  margin: 0;
  padding: 0;
}

.tophalf {
  position: relative;
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  color: white;
  margin: 0;
  padding: 0;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #008cba;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
  background-image: url("report.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 45%;
  background-blend-mode: color-dodge;
}

.overlaybot {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background-color: #0e5032;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.5s ease;
}

.fixed-text-top {
  position: fixed;
  top: 38%;

  transform: translateY(-50%);
  color: #008cba;
  opacity: 100%;
  padding: 0px;
  z-index: 10;
  font-family: system-ui;
  margin: 0;
  font-size: 6vh;
  font-weight: 800;
  letter-spacing: -0.05em;
  pointer-events: none;
  transition:
    top 1.3s ease,
    font-size 1.3s ease,
    color 1.3s ease; /* Added transition */
  white-space: nowrap;
  /*animation: slide 3s ease-in-out infinite alternate;*/
}
.fixed-text-mid {
  position: fixed;
  top: 80%;
  transform: translateY(-50%);
  color: white;
  opacity: 50%;
  padding: 0px;
  z-index: 10;
  font-family: system-ui;
  margin: 0;
  font-size: 12vh;
  font-weight: 800;
  letter-spacing: -0.05em;
  pointer-events: none;
  transition:
    top 1.3s ease,
    font-size 1.3s ease,
    color 1.3s ease; /* Added transition */
  white-space: nowrap;
  animation: slide 3s ease-in-out infinite alternate;
  animation: bounce 3s ease-in-out infinite alternate;
}
.fixed-text-bot {
  position: fixed;
  top: 60%;

  transform: translateY(-50%);
  color: #0e5032;
  padding: 10px;
  z-index: 10;
  font-family: system-ui;
  margin: 0;
  font-size: 6vh;
  font-weight: 800;
  letter-spacing: -0.05em;
  pointer-events: none;
  transition:
    top 1.3s ease,
    font-size 1.3s ease,
    color 1.3s ease; /* Added transition */
  white-space: nowrap;
}

.tophalf:hover ~ .fixed-text-top {
  font-size: 5em;
  color: #005d78;
}

.tophalf:hover ~ .fixed-text-bot {
  top: 80%;
}

.bothalf:hover ~ .fixed-text-bot {
  font-size: 5em;
}

.tophalf:hover .overlay {
  height: 100%;
}
.tophalf:hover {
  height: 80vh;
}
.tophalf:hover ~ .bothalf {
  height: 50vh;
}
.bothalf:hover {
  height: 80vh;
}
.bothalf:hover ~ .tophalf {
  height: 10vh;
}

.bothalf:hover .overlaybot {
  bottom: 0;
  height: 100%;
}
.bothalf:hover ~ .fixed-text-top {
  top: 20%;
}
.bothalf:hover ~ .fixed-text-bot {
  color: #198754;
}

.input_container_top {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.input_container_bot {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.section-1 {
  background-color: #005d78;
}

.section-2 {
  background-color: #00afb9;
}

.section-3 {
  background-color: #fdfcdc;
}

.section-4 {
  background-color: #fed9b7;
}

.section-5 {
  background-color: #f07167;
}

.section-6 {
  background-color: #000000;
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Hide the radio button circles */
.btn-group-vertical input[type="radio"] {
  display: none;
}

.btn-group-vertical .btn.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.scroll-down-indicator {
  text-align: center;
  font-size: 16px;
}

.arrow {
  width: 30px;
  height: 30px;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: 0 auto;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(-5deg);
  }
  40% {
    transform: translateY(-10px) rotate(10deg);
  }
  60% {
    transform: translateY(-5px) rotate(5deg);
  }
}
@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
