.buffs-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.buffs-debuffs-wrapper {
    display: flex;
    justify-content: space-between;
}

.buffs-columns-wrapper {
    width: 66.6%;
}

.debuffs-wrapper {
    width: 33.3%;
    margin-left: 20px;
}

.buffs-columns {
    display: flex;
    justify-content: space-between;
}

.buffs-column {
    width: 48%;
}

.buffs-container h3 {
    margin-bottom: 10px;
}

.buffs-list {
    display: flex;
    flex-direction: column;
}

.buff {
    margin-bottom: 10px;
}

[data-tooltip-id] {
    position: relative;
    cursor: pointer;
}

[data-tooltip-id]:hover {
    text-decoration: underline;
}
