@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.summary-container.animating {
    animation: fadeIn 1s ease-in-out;
}

.summary-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: center; /* Center the container horizontally */
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 1200px;
}

.summary-header {
    display: flex;
    justify-content: center; /* Center the headers */
}

.summary-column-title {
    flex: 1 1 auto;
    padding: 7px;
    color: #ffffff;
    background-color: #195088;
    border-radius: 7px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 1);
    margin: 5px;
    width: 100%;
    border-color: black;
    text-align: center; /* Center the text within the header */
}

.summary-columns {
    display: flex;
    align-items: stretch;
    max-width: 1200px;
    justify-content: center; /* Center the columns */
}

.summary-column {
    flex: 1 1 auto;
    margin: 5px;
    padding: 5px;
    display: flex;
    border-radius: 4px;
    flex-wrap: wrap;
}

.summary-players {
    /*border: 5px solid #990000;*/
    display: ruby;
}

.summary-players > div {
    padding: 0px;
    border-radius: 6px;
    color: #d1d1d1;
    text-align: center;
    margin: 4px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    align-items: baseline;
}

.player-button {
    background-color: #23272b;
    color: #d1d1d1;
    border: none;
    padding: 5px;
    margin: 3px;
    border-radius: 4px;
    cursor: pointer;
}

.player-button:hover {
    background-color: #343a40;
}

/* Class-specific styles */
.warrior {
    background-color: #6b553e !important;
}

.paladin {
    background-color: #b75e85 !important;
}

.hunter {
    background-color: #688144 !important;
}

.rogue {
    background-color: #FFF569 !important;
    color: #000000 !important;
}

.priest {
    background-color: #FFFFFF !important;
    color: #000000 !important;
}

.deathknight {
    background-color: #7c0318 !important;
}

.shaman {
    background-color: #0070DE !important;
}

.mage {
    background-color: #4c93ad !important;
}

.warlock {
    background-color: #625290 !important;
}

.monk {
    background-color: #00FF96 !important;
}

.druid {
    background-color: #b75d0d !important;
}

.demonhunter {
    background-color: #A330C9 !important;
}
.dynamic-content {
    background-color:#444444;
    min-width: 0;
    flex-grow: 1;
    margin: 5px;
    border-radius: 5px;
}
.card-title{
    font-family: "Titillium Web", sans-serif;
    font-weight: 600;
    font-style: normal;
    margin: 10px;
}
