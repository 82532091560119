.boss-selection-container {
    margin: 20px 0;
    display: flex;
    justify-content: center;
}

.boss-selection-dropdown {
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    background-color: #343a40;
    border: 1px solid #495057;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    text-align: left;
}

.boss-selection-dropdown option {
    background-color: #343a40;
    color: #ffffff;
    padding: 10px;
}
.zone-blackwind {
    background-color: darkred !important;
    color: white;
}

.zone-bastion {
    background-color: purple !important;
    color: white;
}

.zone-throne hover {
    background-color: gold !important;;
    color: black;
}
.zone-throne {
    background-color: gold !important;;
    color: black;
}
.skull-icon {
    margin-left: 5px;
    color: black;
}

.list-group-item {
    cursor: pointer;
}

.list-group-item:hover {
    background-color: #f0f0f0;
}
