.player-details {
  background-color: #1e1e1e;
  color: #ffffff;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.player-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.class-spec-icon {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  border-radius: 4px;
}

.player-talents {
  margin-bottom: 10px;
}

.player-gear {
  margin-bottom: 10px;
}

.gear-or-ability-icon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  margin: 2px;
}

.player-report {
  margin-top: 10px;
}
